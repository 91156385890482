import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    {/* <App /> */}


    <div style={{textAlign: "center"}}>


    <h3>
        ALTA Property Services for all of your service needs. ALTA has been providing excellent service since 2010 for Residential and Commercial Properties
      </h3>
      <p>
        Our site is currently under construction to modernize the way we do business as a full service construction/maintenance/plumbing & restoration provider.
      </p>
      <section>
                  <img
                    width="533"
                    height="400"
                    src="images/Brochure.png"
                    alt="snow-plough"
                  />
                  <img
                    width="533"
                    height="400"
                    src="images/Maintenance-Repair.jpg"
                    alt="winterized"
                  />
                  <img
                    width="533"
                    height="400"
                    src="images/Plumbing-Sewer.png"
                    alt="winterized"
                  />
                  <img
                    width="533"
                    height="400"
                    src="images/Remediation.jpg"
                    alt="winterized"
                  />
                  <img
                    width="533"
                    height="400"
                    src="images/Secured-Trashout-Winter.png"
                    alt="winterized"
                  />
                </section>
    </div>
    
  </React.StrictMode>,
  rootElement
);
